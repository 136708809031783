$path: '../fonts/';

$Montserrat: "Montserrat",
Helvetica,
Arial,
sans-serif;

@mixin fontFaceMontserrat($name, $src, $weight, $style) {
	$source: $path+$src;

	@font-face {
		font-family: $name;
		src: url($source + '.woff2') format('woff2'),
		url($source + '.woff') format('woff'),
		url($source + '.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}

@include fontFaceMontserrat('Montserrat', 'Montserrat-ExtraLight', 100, normal);
@include fontFaceMontserrat('Montserrat', 'Montserrat-Light', 300, normal);
@include fontFaceMontserrat('Montserrat', 'Montserrat-Regular', 400, normal);
@include fontFaceMontserrat('Montserrat', 'Montserrat-Medium', 500, normal);
@include fontFaceMontserrat('Montserrat', 'Montserrat-SemiBold', 600, normal);
@include fontFaceMontserrat('Montserrat', 'Montserrat-Bold', 700, normal);
