@use "sass:math";
@import "mixins";
@import "custom";
// @import "variables";

// TO %
@function prc($pxOne, $pxTwo) {
	$result: math.div($pxOne, $pxTwo) * 100%;
	@return $result;
}
// TO REM
@function rem($px) {
	$result: math.div($px, 16) + rem;
	@return $result;
}

// TO EM
@function em($px) {
	$result: math.div($px, 16) + em;
	@return $result;
}

// TO %
@function prc($pxOne, $pxTwo) {
	$result: math.div($pxOne, $pxTwo) * 100%;
	@return $result;
}
// TO REM
@function rem($px) {
	$result: math.div($px, 16) + rem;
	@return $result;
}

// TO EM
@function em($px) {
	$result: math.div($px, 16) + em;
	@return $result;
}

html {
	font-size: calc(0.823vw + 0.0073px);
	// font-size: calc(1.115vw + 0.0073px);
	@media (min-width: 1920px) {
		font-size: 16px;
	}

	@media (max-width: 767.9px) {
		font-size: calc(4.25vw + 0.0073px);
	}
}

.no-scroll {
	overflow: hidden;
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

.page {
	position: relative;
	font-family: $Montserrat;
	font-style: normal;
	background-color: #081517;
	overflow-x: hidden;
	// font-size: calc(0.723vw + 0.0073px);
	// max-width: rem(1920);
	margin: 0 auto;
	// @media (min-width: 1920px) {
	// 	font-size: 16px;
	// }

	.container {
		margin: 0 auto;
		max-width: rem(1220);
		padding: 0 rem(20);
		@media (max-width: 767.9px) {
			padding: 0 rem(21);
		}
	}

	.header {
		&__container {
			max-width: rem(1920);
			padding: 0 rem(64);
			@media (max-width: 767.9px) {
				padding: 0 rem(21);
			}
		}
		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: rem(32) 0;
		}
		&__logo {
			width: rem(305);
			height: rem(70);
			@media (max-width: 767.9px) {
				z-index: 1;
				width: rem(157);
				height: rem(36);
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		&__nav {
			@media (max-width: 767.9px) {
				position: absolute;
				transform: translateX(-100%);
				padding: rem(100) 0 0;
				width: 108%;
				height: 100%;
				top: 0;
				left: -4%;
				background-color: #081517;
			}
			ul {
				display: flex;
				align-items: center;
				gap: rem(67);
				@media (max-width: 767.9px) {
					padding: 0 rem(36);
					flex-direction: column;
					align-items: flex-end;
					gap: rem(24);
				}
				li {
					list-style: none;

					font-style: normal;
					font-weight: 400;
					font-size: rem(24);
					line-height: rem(29);

					@media (max-width: 767.9px) {
						font-size: rem(21);
						line-height: rem(26);
						text-align: right;
					}
					a {
						color: #ffffff;
						text-decoration: none;
						transition: all 0.25 ease-in-out;

						&:hover {
							color: #24b99f;
						}
					}
				}
			}
		}

		&__list {
			&-item {
				font-size: 0.9375rem;
				font-weight: 600;
				line-height: 1.2;
				letter-spacing: 0em;
				text-align: left;
			}
		}

		&__show {
			//z-index: 11;
			.header-catalog {
				z-index: 10;
				background-color: #1c364d;
				opacity: 0;
				&__item {
					color: #ffffff;
				}
			}

			.header-catalog.show {
				opacity: 1;
			}

			.header__nav.show {
				transform: translateX(0);
				// width: 108%;
				// left: -4%;
			}
		}

		&__menu-button {
			width: rem(24);
			height: rem(24);
			display: none;
			z-index: 2;

			@media (max-width: 767.9px) {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.open-menu {
				display: flex;
				align-items: center;
				width: rem(24);
				height: rem(24);

				svg {
					width: rem(24);
					height: rem(24);
				}
			}

			.close-menu {
				display: none;
			}
		}

		&__menu-button_close {
			.open-menu {
				display: none;
			}

			.close-menu {
				display: flex;
				align-items: center;
				width: rem(24);
				height: rem(24);
				svg {
					width: rem(24);
					height: rem(24);
				}
			}
		}
	}

	.hero {
		margin-bottom: rem(250);
		@media (max-width: 767.9px) {
			margin-bottom: rem(90);
		}
		&__wrapper {
			padding-top: rem(220);
			padding-bottom: rem(310);
			@media (max-width: 767.9px) {
				padding-top: rem(40);
				padding-bottom: rem(64);
			}
		}
		&__title {
			margin-bottom: rem(24);
			font-size: rem(64);
			font-weight: 700;
			line-height: 1.21875;
			letter-spacing: 0em;
			text-align: left;
			color: #ffffff;
			@media (max-width: 767.9px) {
				font-size: rem(30);
				font-weight: 700;
				line-height: rem(37);
				letter-spacing: 0;
				text-align: center;
			}
		}
		&__description {
			margin-bottom: rem(80);
			max-width: rem(700);
			font-size: rem(24);
			font-weight: 400;
			line-height: 1.21875;
			letter-spacing: 0;
			text-align: left;
			color: #ffffff;

			@media (max-width: 767.9px) {
				margin-bottom: rem(32);

				font-size: rem(17);
				font-weight: 400;
				line-height: rem(21);
				letter-spacing: 0em;
				text-align: center;
			}
		}
		&__button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: fit-content;
			padding: rem(16) rem(32);
			background: #24b99f;
			color: #ffffff;
			border-radius: rem(3);
			text-decoration: none;

			font-size: rem(24);
			font-weight: 400;
			line-height: 1.208;
			letter-spacing: 0;
			text-align: left;
			transition: all 0.15s linear;
			&:hover {
				opacity: 0.85;
			}

			@media (max-width: 767.9px) {
				width: 100%;
				padding: rem(12) rem(32);
				font-size: rem(16);
				font-weight: 400;
				line-height: rem(20);
				letter-spacing: 0em;
				text-align: left;
			}
		}

		.tasks {
			display: flex;
			flex-direction: row;
			gap: rem(200);
			@media (max-width: 767.9px) {
				gap: rem(24);
				flex-direction: column;
			}
			&__title {
				width: rem(200);
				font-size: rem(48);
				font-weight: 700;
				line-height: 1.23;
				letter-spacing: 0em;
				text-align: left;
				color: #ffffff;
				@media (max-width: 767.9px) {
					width: 100%;
					font-size: rem(26);
					font-weight: 700;
					line-height: rem(32);
					letter-spacing: 0em;
					text-align: center;
				}
			}

			&__list {
				display: flex;
				flex-direction: column;
				gap: rem(22);
				@media (max-width: 767.9px) {
					gap: rem(14);
				}
				&-item {
					font-style: normal;
					font-weight: 400;
					font-size: rem(48);
					line-height: 1.23;
					letter-spacing: 0;
					color: #24b99f;
					cursor: pointer;
					@media (max-width: 767.9px) {
						font-size: rem(21);
						font-weight: 400;
						line-height: rem(26);
						letter-spacing: 0em;
						text-align: left;
					}

					&_text {
						padding-top: rem(10);
						max-height: 0;
						opacity: 0;
						visibility: hidden;
						font-size: rem(24);
						font-weight: 400;
						line-height: rem(29);
						letter-spacing: 0;
						text-align: justified;
						color: #ffffff;
						transition: all 0.15s linear;
						@media (max-width: 767.9px) {
							padding-top: rem(8);
							font-size: rem(17);
							line-height: rem(21);
						}
					}

					&_text.show {
						max-height: rem(3000);
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	.advantages {
		margin-bottom: rem(230);
		@media (max-width: 767.9px) {
			margin-bottom: rem(92);
		}
		&__container {
		}

		&__wrapper {
		}

		&__title {
			margin-bottom: rem(56);
			font-size: rem(48);
			font-weight: 700;
			line-height: 1.23;
			letter-spacing: 0em;
			text-align: left;
			color: #ffffff;
			@media (max-width: 767.9px) {
				margin-bottom: rem(24);
				font-size: rem(26);
				font-weight: 700;
				line-height: rem(32);
				letter-spacing: 0em;
				text-align: center;
			}
		}

		&__items {
			display: flex;
			gap: rem(20);
			@media (max-width: 767.9px) {
				flex-direction: column;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 rem(40);
			width: rem(380);
			height: rem(252);
			background: rgba(255, 255, 255, 0.05);
			box-shadow: 0 0 rem(30) rgba(0, 0, 0, 0.5);
			border-radius: rem(3);
			@media (max-width: 767.9px) {
				width: 100%;
				height: 10.75rem;
				padding: 0 1.5rem;
			}
			&-img {
				margin-bottom: rem(24);
				width: rem(100);
				height: rem(100);

				img {
					width: 100%;
					height: auto;
				}
				@media (max-width: 767.9px) {
					width: rem(64);
					height: rem(64);
				}
			}

			&-text {
				font-size: rem(24);
				font-weight: 400;
				line-height: 1.208;
				letter-spacing: 0em;
				text-align: center;
				color: #ffffff;

				@media (max-width: 767.9px) {
					font-size: rem(17);
					font-weight: 400;
					line-height: rem(21);
					letter-spacing: 0em;
					text-align: center;
				}
			}
		}
	}

	.portfolio {
		margin-bottom: rem(180);
		@media (max-width: 767.9px) {
			margin-bottom: rem(84);
		}
		&__container {
			max-width: 100%;
			padding: 0;
		}

		&__wrapper {
		}

		&__title {
			margin: 0 auto;
			max-width: rem(1220);
			padding: 0 rem(20);

			margin-bottom: rem(56);
			font-size: rem(48);
			font-weight: 700;
			line-height: 1.23;
			letter-spacing: 0em;
			text-align: left;
			color: #ffffff;
			@media (max-width: 767.9px) {
				margin-bottom: rem(24);
				font-size: rem(26);
				font-weight: 700;
				line-height: rem(32);
				letter-spacing: 0em;
				text-align: center;
			}
		}
		&__swiper {
		}
		.swiper-wrapper {
			// padding-right: 500px;
			position: relative;
			display: flex;
			align-items: flex-start;
			margin: 0 auto rem(44) rem(-108);
			// margin-left: -108px;
			max-width: 100%;
			// max-width: rem(1180);
			padding: 0 em(20);
			cursor: pointer;
			counter-reset: item; /* Обнуляем счетчик списка */
			@media (max-width: 767.9px) {
				margin: 0 auto rem(26);
			}
		}

		&__slide {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			gap: rem(32);
			height: 100%;
			width: fit-content;
			// width: rem(867);
			@media (max-width: 767.9px) {
				gap: 1rem;
				padding: 0 rem(21) 0 0;
			}
			&::before {
				content: counter(item) "/07 "; /* Добавляем к числам скобку */
				counter-increment: item; /* Задаём имя счетчика */
				// position: absolute;
				// top: 0;
				// left: 0;
				font-size: rem(26);
				font-weight: 400;
				line-height: rem(32);
				letter-spacing: 0;
				text-align: left;
				color: #ffffff;
				@media (max-width: 767.9px) {
					font-size: rem(21);
					line-height: rem(26);
					opacity: 0.7;
				}
			}

			img {
				max-height: rem(500);
				@media (max-width: 767.9px) {
					max-height: rem(170);
				}
			}

			&-text {
				font-size: rem(24);
				font-weight: 400;
				line-height: rem(29);
				letter-spacing: 0em;
				text-align: left;
				color: #ffffff;
				@media (max-width: 767.9px) {
					font-size: rem(17);
					line-height: rem(21);
				}
			}
		}

		.swiper-scrollbar {
			margin: 0 auto;
			max-width: rem(1212);
			padding: 0;
			@media (max-width: 767.9px) {
				max-width: rem(345);
			}
		}
		.swiper-scrollbar-drag {
			background: #24b99f;
		}
		.swiper-horizontal > .swiper-scrollbar,
		.swiper-scrollbar.swiper-scrollbar-horizontal {
			position: relative;
			background: #1b3431;
			border-radius: rem(3);
		}
	}

	.technologies {
		margin-bottom: rem(220);
		@media (max-width: 767.9px) {
			margin-bottom: rem(72);
		}
		&__container {
		}

		&__wrapper {
		}

		&__title {
			margin-bottom: rem(56);
			font-size: rem(48);
			font-weight: 700;
			line-height: 1.23;
			letter-spacing: 0em;
			text-align: left;
			color: #ffffff;
			@media (max-width: 767.9px) {
				margin-bottom: rem(40);
				font-size: rem(26);
				font-weight: 700;
				line-height: rem(32);
				letter-spacing: 0em;
				text-align: center;
			}
		}

		&__items {
			display: flex;
			flex-wrap: wrap;
			gap: rem(20);
			@media (max-width: 767.9px) {
				flex-wrap: wrap;
				gap: rem(20) 0;
				display: flex;
				justify-content: space-between;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0;
			width: rem(380);
			height: rem(140);
			border-radius: rem(3);

			&-img {
				margin-bottom: rem(24);
			}

			&-text {
				font-size: rem(24);
				font-weight: 400;
				line-height: 1.208;
				letter-spacing: 0em;
				text-align: center;
				color: #ffffff;
			}
			&:nth-child(1) {
				.technologies__item-img {
					width: rem(324);
					height: rem(105);
				}
			}
			&:nth-child(2) {
				.technologies__item-img {
					width: rem(209);
					height: rem(105);
				}
			}
			&:nth-child(3) {
				.technologies__item-img {
					width: rem(281);
					height: rem(105);
				}
			}
			&:nth-child(4) {
				.technologies__item-img {
					width: rem(396);
					height: rem(105);
				}
			}
			&:nth-child(5) {
				.technologies__item-img {
					width: rem(93);
					height: rem(105);
				}
			}
			&:nth-child(6) {
				.technologies__item-img {
					width: rem(362);
					height: rem(88);
				}
			}
			@media (max-width: 767.9px) {
				width: auto;
				height: rem(64);

				&:nth-child(1) {
					order: 4;
					.technologies__item-img {
						width: rem(154);
						height: rem(50);
					}
				}
				&:nth-child(2) {
					order: 6;
					.technologies__item-img {
						width: rem(99);
						height: rem(50);
					}
				}
				&:nth-child(3) {
					order: 3;
					.technologies__item-img {
						width: rem(134);
						height: rem(50);
					}
				}
				&:nth-child(4) {
					order: 1;

					.technologies__item-img {
						width: rem(239);
						height: rem(64);
					}
				}
				&:nth-child(5) {
					order: 2;

					.technologies__item-img {
						width: rem(56);
						height: rem(64);
					}
				}
				&:nth-child(6) {
					order: 5;

					.technologies__item-img {
						width: rem(205);
						height: rem(50);
					}
				}
			}
		}
	}

	.form {
		position: relative;
		margin: 0 auto rem(180);
		min-height: rem(376);
		height: fit-content;
		@media (max-width: 767.9px) {
			margin: 0 auto rem(92);
		}

		&:before {
			content: "";
			z-index: -1;
			position: absolute;
			top: -129%;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: rem(1735);
			background-image: url("../img/form-bg.svg");
			background-repeat: no-repeat;
			background-size: cover;
			opacity: 0.8;
			@media (max-width: 767.9px) {
			}
		}

		&__title {
			margin-bottom: rem(40);
			font-size: rem(48);
			font-weight: 700;
			line-height: 1.23;
			letter-spacing: 0em;
			text-align: left;
			color: #ffffff;
			@media (max-width: 767.9px) {
				margin-bottom: rem(24);
				font-size: rem(26);
				font-weight: 700;
				line-height: rem(32);
				letter-spacing: 0em;
				text-align: center;
			}
		}

		&__text {
			margin-bottom: rem(46);
			max-width: rem(700);
			font-size: rem(24);
			font-weight: 400;
			line-height: 1.25;
			letter-spacing: 0em;
			text-align: left;
			color: #ffffff;
			@media (max-width: 767.9px) {
				margin-bottom: rem(16);
				font-size: rem(17);
				line-height: rem(21);
			}
			p {
				margin-bottom: rem(20);
				a {
					color: #24b99f;
					&:hover, &:visited {
						color: #24b99f;
					}
					
				}
			}
		}
		.text-field {
			&__container {
				display: flex;
				gap: rem(20);
				@media (max-width: 767.9px) {
					flex-direction: column;
					gap: rem(9);
				}
			}
			&__inputs {
				width: 40.75%;
				@media (max-width: 767.9px) {
					width: 100%;
				}
			}

			&__textarea {
				width: 49%;
				@media (max-width: 767.9px) {
					width: 100%;
				}

				.text-field__wrapper {
					margin-bottom: 0;
				}
			}
			&__wrapper {
				margin-bottom: rem(36);

				@media (max-width: 767.9px) {
					margin-bottom: rem(16);
				}

				&-line {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: space-between;
					@media (max-width: 767.9px) {
					}

					.text-field__block {
						width: 49%;
						@media (max-width: 767.9px) {
						}
					}
				}
			}

			&__block {
				position: relative;
				input {
					height: rem(40);
					width: 100%;
					color: #fff;
					background-color: #0000;
					border: none;
					border-bottom: rem(1) solid #24b99f;
					padding: 0 0 0 rem(16);

					font-size: rem(16);
					font-weight: 400;
					line-height: rem(20);
					letter-spacing: 0em;
					text-align: left;

					position: relative;
					@media (max-width: 767.9px) {
						height: rem(34);
						font-size: rem(14);
						line-height: rem(17);
						padding: 0 0 0 rem(8);
					}
					&:focus {
						border-color: #ffffff;
						outline: none;
					}
					&::placeholder {
						color: #ffffff;
					}
				}

				input.error {
					border-color: #fd2c2c;
					border-bottom: rem(1) solid #fd2c2c;
					outline: none;
					margin-top: 0;
				}

				.error {
					margin-top: 0.5em;
					color: #fd2c2c;
				}
			}

			&__label {
				position: absolute;
				top: 0;
				left: 0;
				width: 80%;
				height: auto;
				padding: 1.333rem 2rem;
				pointer-events: none;
				color: #676767;
				opacity: 0.5;
				border: rem(1) solid transparent;
				transform-origin: 0 0;
				transition: color 0.15s ease-in-out, transform 0.15s ease-in-out;
				font-weight: 400;
				font-size: rem(16);
				line-height: 145%;
			}

			&__comment {
				&-textarea {
					position: relative;

					&_item {
						display: block;
						overflow: hidden;
						// resize: vertical;
						// height: 36px;
						resize: none;
						width: 100%;
						min-height: rem(193);
						padding: rem(12) rem(16);

						font-size: rem(16);
						font-weight: 400;
						line-height: rem(20);
						letter-spacing: 0em;
						text-align: left;
						color: #ffffff;

						background-color: transparent;
						border: rem(1) solid #24b99f;
						border-radius: rem(3);

						transition: all 0.25s ease-in-out;

						@media (max-width: 767.9px) {
							font-size: rem(14);
							line-height: rem(17);
						}
						&:focus {
							border-color: #ffffff;
							outline: none;
						}

						&::placeholder {
							color: #ffffff;
						}
					}

					&_item.error {
						border-color: #fd2c2c;
						border-bottom: rem(2) solid #fd2c2c;
						outline: none;
						margin-top: 0;
					}

					.error {
						color: #fd2c2c;
					}
				}

				&-textarea.resize {
					&::before {
						content: "";
						// bottom: 10px;
						transform: translateY(14px);
					}
				}
			}

			&__send {
				margin-top: rem(20);
				@media (max-width: 767.9px) {
					margin-top: rem(16);
				}
				&_row {
					justify-content: space-between;
					align-items: center;
					display: flex;
					gap: 6em;
					margin-bottom: rem(16);
				}
				.text-field__button {
					font-size: rem(24);
					font-weight: 400;
					line-height: rem(29);
					letter-spacing: 0em;
					text-align: left;

					width: fit-content;
					text-align: center;
					padding: rem(16) rem(32);
					color: #fff;
					background: #24b99f;
					border-radius: rem(3);
					border: none;
					cursor: pointer;
					transition: all 0.15s ease-in-out;
					@media (max-width: 767.9px) {
						font-size: rem(16);
						line-height: rem(20);
						padding: rem(12) rem(32);
					}
					&[disabled="true"] {
						background: #24b99f;
						opacity: 0.5;
						cursor: not-allowed;
					}
					&:disabled {
						background: #24b99f;
						opacity: 0.5;
						cursor: not-allowed;
						&:hover {
							background: #24b99f;
							opacity: 0.5;
						}
					}

					&:hover {
						background: #24b99f;
						opacity: 0.85;
					}
					@media (max-width: 768px) {
						width: 100%;
						max-width: rem(385);
					}
				}

				.text-field__loader {
					margin-left: auto;
					width: fit-content;
					font-size: rem(17);
					line-height: rem(20);
					color: #fff;
					@media (max-width: 767.9px) {
					}
				}
				.text-field__loader.text-field__hidden {
					display: none;
				}
				.text-field__success {
					margin-left: auto;
					width: fit-content;
					font-size: rem(17);
					line-height: rem(20);
					color: #fff;
					@media (max-width: 767.9px) {
					}
				}
				.text-field__success.text-field__hidden {
					display: none;
				}

				.text-field__error {
					margin-left: auto;
					width: fit-content;
					font-size: rem(17);
					line-height: rem(20);
					color: #fff;
					@media (max-width: 767.9px) {
					}
				}
				.text-field__error.text-field__hidden {
					display: none;
				}
			}
		}
	}

	.clients {
		margin-bottom: rem(90);
		@media (max-width: 767.9px) {
			margin-bottom: 0;
		}
		&__container {
		}

		&__wrapper {
		}

		&__title {
			margin-bottom: rem(56);
			font-size: rem(48);
			font-weight: 700;
			line-height: 1.23;
			letter-spacing: 0em;
			text-align: left;
			color: #ffffff;
			@media (max-width: 767.9px) {
				margin-bottom: rem(24);
				font-size: rem(26);
				font-weight: 700;
				line-height: rem(32);
				letter-spacing: 0em;
				text-align: center;
			}
		}

		&__items {
			display: flex;
			flex-wrap: wrap;
			gap: rem(20);
		}

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 rem(24);
			width: rem(380);
			height: rem(200);

			background: rgba(255, 255, 255, 0.05);
			box-shadow: 0 0 rem(30) rgba(0, 0, 0, 0.5);
			border-radius: rem(3);

			&:nth-child(1) {
				.clients__item-img {
					width: rem(281);
					height: rem(60);
				}
			}
			&:nth-child(2) {
				.clients__item-img {
					width: rem(162);
					height: rem(86);
				}
			}
			&:nth-child(3) {
				.clients__item-img {
					width: rem(281);
					height: rem(53);
				}
			}
			&:nth-child(4) {
				.clients__item-img {
					width: rem(263);
					height: rem(83);
				}
			}
			&:nth-child(5) {
				.clients__item-img {
					width: rem(262);
					height: rem(45);
				}
			}
			// &:nth-child(6) {
			// 	.clients__item-img {
			// 		width: rem(205);
			// 		height: rem(50);
			// 	}
			// }

			@media (max-width: 767.9px) {
				width: 9.75rem;
				height: 5.5rem;

				&:nth-child(1) {
					order: 1;
					.clients__item-img {
						width: rem(109);
						height: rem(23);
					}
				}
				&:nth-child(2) {
					order: 5;
					.clients__item-img {
						width: rem(77);
						height: rem(41);
					}
				}
				&:nth-child(3) {
					order: 2;
					.clients__item-img {
						width: rem(108);
						height: rem(21);
					}
				}
				&:nth-child(4) {
					order: 3;

					.clients__item-img {
						width: rem(109);
						height: rem(35);
					}
				}
				&:nth-child(5) {
					order: 4;

					.clients__item-img {
						width: rem(108);
						height: rem(19);
					}
				}
				&:nth-child(6) {
					order: 6;
				}
			}

			&-img {
			}

			&-text {
				padding: 0 0 0 rem(24);
				background: none;
				box-shadow: none;
				border-radius: 0;
				font-size: rem(36);
				font-weight: 400;
				line-height: rem(50);
				letter-spacing: 0;
				text-align: left;
				color: #24b99f;

				@media (max-width: 767.9px) {
					padding: 0;
					font-size: rem(17);
					line-height: rem(20);
				}
			}
		}
	}

	.footer {
		padding: rem(90) 0 rem(60);
		@media (max-width: 767.9px) {
			padding: rem(90) 0 rem(40);
		}
		&__container {
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&__title {
			margin-bottom: rem(24);
			font-size: rem(26);
			font-weight: 400;
			line-height: rem(32);
			letter-spacing: 0;
			text-align: center;
			color: #24b99f;
			text-transform: uppercase;
			@media (max-width: 767.9px) {
				margin-bottom: rem(30);
				font-size: rem(17);
				line-height: rem(21);
			}
		}

		&__phone {
			margin-bottom: rem(8);
			display: block;
			font-size: rem(16);
			font-weight: 400;
			line-height: rem(20);
			letter-spacing: 0em;
			text-align: center;
			color: #ffffff;
			text-decoration: none;
			@media (max-width: 767.9px) {
				font-size: rem(14);
				line-height: rem(17);
			}
		}

		&__mail {
			display: block;
			font-size: rem(16);
			font-weight: 400;
			line-height: rem(20);
			letter-spacing: 0;
			text-align: center;
			color: #ffffff;
			text-decoration: none;
			@media (max-width: 767.9px) {
				font-size: rem(14);
				line-height: rem(17);
			}
		}
	}

	.page-bg-left {
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.4;
		img {
			width: 100%;
			height: auto;
		}
	}

	.page-bg-right {
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0.6;
		img {
			width: 100%;
			height: auto;
		}
	}

	.page-bg-bottom {
		z-index: -1;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0.8;
		img {
			width: 100%;
			height: auto;
		}
	}

	@keyframes form-bounce {
		0% {
			transform: translateX(0px);
			timing-function: ease-in;
		}
		37% {
			transform: translateX(5px);
			timing-function: ease-out;
		}
		55% {
			transform: translateX(-5px);
			timing-function: ease-in;
		}
		73% {
			transform: translateX(4px);
			timing-function: ease-out;
		}
		82% {
			transform: translateX(-4px);
			timing-function: ease-in;
		}
		91% {
			transform: translateX(2px);
			timing-function: ease-out;
		}
		96% {
			transform: translateX(-2px);
			timing-function: ease-in;
		}
		100% {
			transform: translateX(0px);
			timing-function: ease-in;
		}
	}
}
